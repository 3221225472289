import React, { useEffect, useState } from "react";
import axios from "axios";
import { getAccessToken } from "../utils/Auth";
import Constants from "../utils/Constants";
import { formatDate, getTimeDeltaHuman, makeAlphanumeric } from "../utils/Functions";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Button, Typography, TextField, InputLabel, Select, makeStyles } from "@material-ui/core";
import { MenuItem, Switch } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import DeployDialog from "./DeployDialog";
import DeployDialogWindows from "./DeployDialogWindows"
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { ValidatedTextField, anyErrors } from "../utils/ValidatedTextField";

const http_str = "https://"
const base_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/analyzers')
const cfn_base_url = 'https://console.aws.amazon.com/cloudformation/home?region='
const cfn_stack = '#/stacks/new?stackName=' + Constants.applicationName + 'Analyzer-'
const cfn_sig = '&templateURL='
const vpc_base_url = '.console.aws.amazon.com/vpcconsole/home?region='


const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'red',
        },
        '& .MuiPaper-root': {
            backgroundColor: '#333333',
        }
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#1be01e',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#5861fc',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#fc0d31',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWidget: {
        margin: '5px',
        color: '#CCCCCC',
        justify: 'flex',
        alignItems: 'center',
    },
    inputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'baseline',
        '& .MuiSelect-filled': {
            color: 'black',
            backgroundColor: '#555555',
        },
        '& .MuiSelect-iconFilled': {
            color: 'black',
        },
    },
    accordian: {
        color: '#EEE',
        justifyContent: "unset",
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

export default function AnalyzersDialog({ open, onClose, onSave, formData, setFormData, siteData, policyData, requiresSave, setRequiresSave}) {
    const classes = useStyles()
    const [deployMode, setDeployMode] = useState(false)
    const [deployWindowsMode, setDeployWindowsMode] = useState(false)
    const [href, setHref] = useState('')
    const [cfn, setCfn] = useState('')
    const [installerUrl, setInstallerUrl] = useState('')
    const [configUrl, setConfigUrl] = useState('')
    // const [requiresSave, setRequiresSave] = useState(false)
    const [error, setError] = useState([])
    const [isRequiredFieldEmpty, setIsRequiredFieldEmpty] = useState(true)

    const defaultPlaceholderSubnets = `Subnet IDs\n1 per line\nmax 3`
    const [placeholderSubnets, setPlaceholderSubnets] = useState(defaultPlaceholderSubnets)

    const log_level_menu = ['ERROR', 'WARN', 'INFO', 'DEBUG']
    const storage_type_menu = ['SMB', 'S3', 'GDrive']
    const alert_type_menu = ['', 'AWS SNS']
    const size_menu = ['SMALL', 'MEDIUM', 'LARGE']
    let title = 'Analyzers'


    if (formData.pk) {
        title = 'Edit Analyzer: ' + formData.pk
    }
    else {
        title = 'Add Analyzer'
    }

    const getFileUrl = async (uuid) => {
        let url = base_url + "/" + uuid + '?template=cloudformation'
        const headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        await axios
            .get(url, { headers: headers })
            .then(res => {
                const file_url = res.data['msg']['url1']
                setHref(file_url)
                const cfn_url = cfn_base_url + formData.region +
                    cfn_stack + formData.pk +
                    "&param_NodeName=" + formData.pk +
                    "&param_DomainName=" + Constants.domainName +
                    "&param_AuthUrl=" + Constants.authDomain +
                    cfn_sig + encodeURIComponent(file_url)
                setCfn(cfn_url)

            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
            })
    }

    const getWindowsFileUrl = async (uuid) => {
        let url = base_url + "/" + uuid + '?template=windows'
        const headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        await axios
            .get(url, { headers: headers })
            .then(res => {
                const installer_url = res.data['msg']['url1']
                setInstallerUrl(installer_url)
                const config_url = res.data['msg']['url2']
                setConfigUrl(config_url)
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
            })
    }

    function dynamic_fields(type) {
        // This is for storage specific fields, i.e. S3 vs SMB
        if (type === 'S3') {
            return (
                <>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Site</InputLabel>
                    </Grid>
                    <Grid item xs={5} className={classes.inputRow}>
                        <Select
                              name="site_uuid"
                              label="Site"
                              variant="outlined"
                              value={formData.site_uuid}
                              onChange={handleChange}
                              classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                              style={{minWidth: 200}}
                            >
                            {siteData.map((formData) =>
                                <MenuItem key={formData.pk} value={formData.pk}>{formData.site_name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Region</InputLabel>
                    </Grid>
                    <Grid item xs={3}>
                        <ValidatedTextField
                            id='region'
                            name='region'
                            label='Region*'
                            value={formData.region}
                            onChange={handleChange}
                            variant={"outlined"}
                            //className={classes.dialog}
                            classes={{ root: classes.whiteColor }}
                            sx={{
                                width: 125
                              }}
                            inputProps={{ style: { color: "#FFF" } }}
                            InputLabelProps={{ style: { color: "#AAA" } }}
                            error={error}
                            setError={setError}
                            mode='aws-region'
                            required='required'
                            maxLength={16}
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Policy</InputLabel>
                    </Grid>
                    <Grid item xs={5} className={classes.inputRow}>
                        <Select
                            name="policy_uuid"
                            label="Policy"
                            variant="outlined"
                            value={formData.policy_uuid}
                            onChange={handleChange}
                            classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                            style={{minWidth: 200}}
                        >
                        {policyData.map((formData) =>
                            <MenuItem key={formData.pk} value={formData.pk}>{formData.policy_name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Engine Size</InputLabel>
                    </Grid>
                    <Grid item xs={3} className={classes.inputRow}>
                        <Select
                            name='size'
                            label='Size'
                            value={formData.size}
                            onChange={handleChange}
                            variant={"outlined"}
                            //className={classes.dialog}
                            classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                              style={{minWidth: 125}}
                        >
                            {size_menu.map((item) =>
                                <MenuItem key={item} value={item}>{item}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>SQS Queue:</InputLabel>
                    </Grid>
                    <Grid item xs={10} className={classes.inputRow}>
                        <ValidatedTextField
                            fullWidth
                            name='sqs_queue'
                            label='Name*'
                            value={formData.sqs_queue}
                            onChange={handleChange}
                            variant={"outlined"}
                            //className={classes.dialog}
                            classes={{root: classes.whiteColor}}
                            sx={{
                                width: 350
                            }}
                            inputProps={{ style: { color: "#FFF" } }}
                            InputLabelProps={{ style: { color: "#AAA" } }}
                            error={error}
                            setError={setError}
                            mode='default'
                            required={formData.monitored_storage_type === 'S3' ? 'required': null}
                        />
                    </Grid>
                    <Accordion className={classes.accordian}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{
                                bgColor: "#555555"
                            }}
                        >
                        Networking
                        </AccordionSummary>
                        <AccordionDetails className={classes.dialog}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <InputLabel className={classes.dialogWidget}>VPC</InputLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValidatedTextField
                                        fullWidth
                                        name='vpc_id'
                                        label='VPC ID*'
                                        value={formData.vpc_id}
                                        onChange={handleChange}
                                        variant={"outlined"}
                                        //className={classes.dialog}
                                        classes={{ root: classes.whiteColor }}
                                        sx={{
                                            width: 300
                                          }}
                                        inputProps={{ style: { color: "#FFF" } }}
                                        InputLabelProps={{ style: { color: "#AAA" } }}
                                        error={error}
                                        setError={setError}
                                        mode='aws-vpc-id'
                                        required='required'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <CloudOutlinedIcon color={formData.region && formData.site_uuid !== '' ? "primary" : "error"} onClick={event => window.open(getVpcUrl('vpc'), '_blank')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel className={classes.dialogWidget}>Subnets</InputLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValidatedTextField
                                        fullWidth
                                        multiline
                                        maxRows={3}
                                        name='subnets'
                                        label='Subnet IDs*'
                                        placeholder={placeholderSubnets}
                                        value={formData.subnets?.join("\n")}
                                        onChange={handleChange}
                                        variant={"outlined"}
                                        //className={classes.dialog}
                                        classes={{ root: classes.whiteColor }}
                                        sx={{
                                            width: 300
                                          }}
                                        inputProps={{ style: { color: "#FFF" } }}
                                        InputLabelProps={{ style: { color: "#AAA" } }}
                                        error={error}
                                        setError={setError}
                                        mode='aws-subnet-id'
                                        required='required'
                                        maxLength={80}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <CloudOutlinedIcon color={formData.region && formData.site_uuid !== '' ? "primary" : "error"} onClick={event => window.open(getVpcUrl('subnet'), '_blank')} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </>
            )
        }
        if (type === 'GDrive') {
            return (
                <>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Site</InputLabel>
                    </Grid>
                    <Grid item xs={5} className={classes.inputRow}>
                        <Select
                              name="site_uuid"
                              label="Site"
                              variant="outlined"
                              value={formData.site_uuid}
                              onChange={handleChange}
                              classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                              style={{minWidth: 200}}
                            >
                            {siteData.map((formData) =>
                                <MenuItem key={formData.pk} value={formData.pk}>{formData.site_name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Region</InputLabel>
                    </Grid>
                    <Grid item xs={3}>
                        <ValidatedTextField
                            id='region'
                            name='region'
                            label='Region*'
                            value={formData.region}
                            onChange={handleChange}
                            variant={"outlined"}
                            //className={classes.dialog}
                            classes={{ root: classes.whiteColor }}
                            sx={{
                                width: 125
                              }}
                            inputProps={{ style: { color: "#FFF" } }}
                            InputLabelProps={{ style: { color: "#AAA" } }}
                            error={error}
                            setError={setError}
                            mode='aws-region'
                            required='required'
                            maxLength={16}
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Policy</InputLabel>
                    </Grid>
                    <Grid item xs={5} className={classes.inputRow}>
                        <Select
                            name="policy_uuid"
                            label="Policy"
                            variant="outlined"
                            value={formData.policy_uuid}
                            onChange={handleChange}
                            classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                            style={{minWidth: 200}}
                        >
                        {policyData.map((formData) =>
                            <MenuItem key={formData.pk} value={formData.pk}>{formData.policy_name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Engine Size</InputLabel>
                    </Grid>
                    <Grid item xs={3} className={classes.inputRow}>
                        <Select
                            name='size'
                            label='Size'
                            value={formData.size}
                            onChange={handleChange}
                            variant={"outlined"}
                            //className={classes.dialog}
                            classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                              style={{minWidth: 125}}
                        >
                            {size_menu.map((item) =>
                                <MenuItem key={item} value={item}>{item}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Topic:</InputLabel>
                    </Grid>
                    <Grid item xs={10} className={classes.inputRow}>
                        <ValidatedTextField
                            fullWidth
                            name='sqs_queue'
                            label='Name*'
                            value={formData.sqs_queue}
                            onChange={handleChange}
                            variant={"outlined"}
                            //className={classes.dialog}
                            classes={{root: classes.whiteColor}}
                            sx={{
                                width: 350
                            }}
                            inputProps={{ style: { color: "#FFF" } }}
                            InputLabelProps={{ style: { color: "#AAA" } }}
                            error={error}
                            setError={setError}
                            mode='default'
                            required={'required'}
                        />
                    </Grid>
                    <Accordion className={classes.accordian}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{
                                bgColor: "#555555"
                            }}
                        >
                        Networking
                        </AccordionSummary>
                        <AccordionDetails className={classes.dialog}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <InputLabel className={classes.dialogWidget}>VPC</InputLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValidatedTextField
                                        fullWidth
                                        name='vpc_id'
                                        label='VPC ID*'
                                        value={formData.vpc_id}
                                        onChange={handleChange}
                                        variant={"outlined"}
                                        //className={classes.dialog}
                                        classes={{ root: classes.whiteColor }}
                                        sx={{
                                            width: 300
                                          }}
                                        inputProps={{ style: { color: "#FFF" } }}
                                        InputLabelProps={{ style: { color: "#AAA" } }}
                                        error={error}
                                        setError={setError}
                                        mode='aws-vpc-id'
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <CloudOutlinedIcon color={formData.region && formData.site_uuid !== '' ? "primary" : "error"} onClick={event => window.open(getVpcUrl('vpc'), '_blank')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel className={classes.dialogWidget}>Subnets</InputLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValidatedTextField
                                        fullWidth
                                        multiline
                                        maxRows={3}
                                        name='subnets'
                                        label='Subnet IDs*'
                                        placeholder={placeholderSubnets}
                                        value={formData.subnets.join("\n")}
                                        onChange={handleChange}
                                        variant={"outlined"}
                                        //className={classes.dialog}
                                        classes={{ root: classes.whiteColor }}
                                        sx={{
                                            width: 300
                                          }}
                                        inputProps={{ style: { color: "#FFF" } }}
                                        InputLabelProps={{ style: { color: "#AAA" } }}
                                        error={error}
                                        setError={setError}
                                        mode='aws-subnet-id'
                                        maxLength={80}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <CloudOutlinedIcon color={formData.region && formData.site_uuid !== '' ? "primary" : "error"} onClick={event => window.open(getVpcUrl('subnet'), '_blank')} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </>
            )
        }
        else if (type === 'SMB') {
            return (
                <>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Site</InputLabel>
                    </Grid>
                    <Grid item xs={5} className={classes.inputRow}>
                        <Select
                              name="site_uuid"
                              label="Site"
                              variant="outlined"
                              value={formData.site_uuid}
                              onChange={handleChange}
                              classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                              style={{minWidth: 200}}
                            >
                            {siteData.map((formData) =>
                                <MenuItem key={formData.pk} value={formData.pk}>{formData.site_name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Location</InputLabel>
                    </Grid>
                    <Grid item xs={3}>
                        <ValidatedTextField
                            id='region'
                            name='region'
                            label='Location'
                            value={formData.region}
                            onChange={handleChange}
                            variant={"outlined"}
                            //className={classes.dialog}
                            classes={{ root: classes.whiteColor }}
                            sx={{
                                width: 125
                              }}
                            inputProps={{ style: { color: "#FFF" } }}
                            InputLabelProps={{ style: { color: "#AAA" } }}
                            error={error}
                            setError={setError}
                            mode='aws-region'
                            hoverText='enter AWS region'
                            required={false}
                            maxLength={16}
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Policy</InputLabel>
                    </Grid>
                    <Grid item xs={5} className={classes.inputRow}>
                        <Select
                            name="policy_uuid"
                            label="Policy"
                            variant="outlined"
                            value={formData.policy_uuid}
                            onChange={handleChange}
                            classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                            style={{minWidth: 200}}
                        >
                        {policyData.map((formData) =>
                            <MenuItem key={formData.pk} value={formData.pk}>{formData.policy_name}</MenuItem>)}
                        </Select>
                    </Grid>
                </>
            )
        }
    }

    function alerting_section() {
        // This is for alert specific fields, e.g SNS, SecurityHub, SIEM etc
        return (
            <>
                <Accordion className={classes.accordian}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                            bgColor: "#555555"
                        }}
                    >
                    Alerting
                    </AccordionSummary>
                    <AccordionDetails className={classes.dialog}>
                        <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>
                            <em>Note: Alerts are always sent to this Admin Portal</em></Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <InputLabel className={classes.dialogWidget}>Enable Alerts</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <Switch
                                    className={classes.dialogWidget}
                                    name="send_alerts"
                                    checked={formData.send_alerts}
                                    onClick={handleCheckboxChange}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <InputLabel className={classes.dialogWidget}>Alert Type</InputLabel>
                            </Grid>
                            <Grid item xs={4} className={classes.inputRow}>
                                <Select
                                    name='alert_type'
                                    label='Type'
                                    // defaultValue='AWS SNS'
                                    value={formData.alert_type}
                                    onChange={handleChange}
                                    variant={"outlined"}
                                    //className={classes.dialog}
                                    classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                      style={{minWidth: 125}}
                                >
                                {alert_type_menu.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>)}
                                </Select>
                            </Grid>
                            <Grid item xs={2} className={classes.inputRow}>
                                <InputLabel className={classes.dialog}>SNS Arn</InputLabel>
                            </Grid>
                            <Grid item xs={10} className={classes.inputRow}>
                                <TextField
                                    fullWidth
                                    name='sns_topic_arn'
                                    label='SNS Arn'
                                    value={formData.sns_topic_arn}
                                    // onChange={handleChange}
                                    variant={"outlined"}
                                    //className={classes.dialog}
                                    classes={{ root: classes.whiteColor }}
                                    inputProps={{ style: { color: "#FFF" } }}
                                    InputLabelProps={{ style: { color: "#AAA" } }}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    const getVpcUrl = (type) => {
        let url = http_str.concat(formData.region, vpc_base_url, formData.region, '#', type, 's:')
        return url
    }

    const updateSnsArn = (region, account, pk) => {
        const base = 'arn:aws:sns:'
        let arn = base.concat(region, ':', account, ':' + Constants.applicationName.toLowerCase() + '-', region, '-', pk, '-alerts')
        setFormData((prevFormData) => ({...prevFormData, ['sns_topic_arn']: arn}))
    }

    const updateSqs = (region, account, pk) => {
        let sqs = Constants.applicationName.toLowerCase() + '-' + region + '-' + pk + '-queue'
        setFormData((prevFormData) => ({...prevFormData, ['sqs_queue']: sqs}))
    }

    const updateSuspendPolicyArn = (account) => {
        let suspend = 'arn:aws:iam::' + account + ':policy/RansomStopSuspendUserAccountAWS'
        setFormData((prevFormData) => ({...prevFormData, ['suspend_user_policy']: suspend}))
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        console.log(name + ": " + value)
        setRequiresSave(true)
        if (name === 'pk') {
            setFormData((prevFormData) => ({...prevFormData, [name]: makeAlphanumeric(value)}))
        }
        else if (name === 'subnets') {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value.split('\n') }))
        }
        else if (name === 'region') {
            if (formData['monitored_storage_type'] === 'S3') {
                setFormData((prevFormData) => ({...prevFormData, [name]: value}))
                updateSqs(value, formData.aws_account_id, formData.pk)
                updateSnsArn(value, formData.aws_account_id, formData.pk)
            }
        }
        else if (name === 'site_uuid') {
            siteData.forEach((element, index, array) => {
                if (element.pk === value) {
                    setFormData((prevFormData) => ({...prevFormData, ['site_uuid']: element.pk}))
                    setFormData((prevFormData) => ({...prevFormData, ['site_name']: element.site_name}))
                    setFormData((prevFormData) => ({...prevFormData, ['aws_account_id']: element.aws_account_id}))
                    updateSnsArn(formData.region, element.aws_account_id, formData.pk)
                    updateSuspendPolicyArn(element.aws_account_id)
                }
            })
        }
        else if (name === 'alert_type') {
            console.log(JSON.stringify(formData))
            setFormData((prevFormData) => ({...prevFormData, [name]: value}))
            if (value === 'AWS SNS') {
                // @TODO seems to be a bug here where formData.aws_account_id is undefined...
                updateSnsArn(formData.region, formData.aws_account_id, formData.pk)
            }
        }
        else if (name === 'subnets') {
            if (value === '') {
                setPlaceholderSubnets(defaultPlaceholderSubnets)
            } 
            else {
                setPlaceholderSubnets('');
            }

        }
        else {
            setFormData((prevFormData) => ({...prevFormData, [name]: value}))
        }
	};

    const handleCheckboxChange = (event) => {
        setRequiresSave(true)
        const { name } = event.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: event.target.checked }))
	};

    const handleOpenDeploy = () => {
        if (formData.monitored_storage_type === 'S3') {
            setDeployMode(true);
            console.log("deploy AWS site/analyzer")
        }
        else if (formData.monitored_storage_type === 'SMB') {
            setDeployWindowsMode(true);
            console.log("deploy windows analyzer")
        }
    };

    const handleCloseDeploy = () => {
        setDeployMode(false);
        setDeployWindowsMode(false)
    };

    const handleSubmit = (event) => {
        event.preventDefault()
        onSave(formData)
        // setRequiresSave(false)
    }

    useEffect(() => {
        if (formData) {
            if (formData.monitored_storage_type === 'S3') {
                console.log("fetch AWS URLs")
                const file_prom = getFileUrl(formData.pk)
            }
            else if (formData.monitored_storage_type === 'SMB') {
                console.log("fetch Windows URLs")
                const file_prom = getWindowsFileUrl(formData.pk)
            }
        }
    }, [formData]);

    useEffect(() => {
        if ( (formData.monitored_storage_type === 'S3') && (
            formData.pk.trim() === '' ||
            formData.site_uuid.trim() === '' ||
            formData.policy_uuid.trim() === '' ||
            formData.region.trim() === '' ||
            formData.sqs_queue.trim() === '' ||
            formData.vpc_id.trim() === '' ||
            formData.subnets.length === 0 ||
            formData.log_interval.trim() === '') ) {
                setIsRequiredFieldEmpty(true)
        } else if ( (formData.monitored_storage_type === 'SMB') && (
            formData.pk.trim() === '' ||
            formData.site_uuid.trim() === '' ||
            formData.policy_uuid.trim() === '' ||
            formData.log_interval.trim() === '') ) {
                setIsRequiredFieldEmpty(true)
        } else {
            setIsRequiredFieldEmpty(false)
        }
        
    }, [
        formData.pk,
        formData.site_uuid,
        formData.policy_uuid,
        formData.sqs_queue,
        formData.region,
        formData.vpc_id,
        formData.subnets,
        formData.log_interval,
        formData.monitored_storage_type
    ])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                width: 600
              }}
            fullWidth={true}
            maxWidth = "sm"
        >
            <DialogTitle className={classes.dialogTitleBar}>{title}</DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText component={'div'} className={classes.dialog}>
                    <Grid container spacing={2}>
                        <Grid item xs={2} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Name</InputLabel>
                        </Grid>
                        <Grid item xs={5}>
                            <ValidatedTextField
                                id='name'
                                name='pk'
                                label='Name*'
                                value={formData.pk}
                                onChange={handleChange}
                                variant={"outlined"}
                                classes={{ root: classes.whiteColor }}
                                sx={{
                                    width: 200
                                  }}
                                inputProps={{ style: { color: "#FFF" } }}
                                InputLabelProps={{ style: { color: "#AAA" } }}
                                error={error}
                                setError={setError}
                                mode='default-1'
                                required='required'
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Storage Type</InputLabel>
                        </Grid>
                        <Grid item xs={3} className={classes.inputRow}>
                            <Select
                                required
                                name='monitored_storage_type'
                                label='Type'
                                defaultValue='SMB'
                                value={formData.monitored_storage_type}
                                onChange={handleChange}
                                variant={"outlined"}
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                  style={{minWidth: 125}}
                            >
                                {storage_type_menu.map((item) =>
                                    <MenuItem key={item} value={item}>{item}</MenuItem>)}
                            </Select>
                        </Grid>
                        {dynamic_fields(formData.monitored_storage_type)}
                        {alerting_section()}
                        <Accordion className={classes.accordian}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                    bgColor: "#555555"
                                }}
                            >
                                Logging
                            </AccordionSummary>
                            <AccordionDetails className={classes.dialog}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <InputLabel className={classes.dialogWidget}>Log Level</InputLabel>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Select
                                            name="log_level"
                                            label="Log Level"
                                            variant="outlined"
                                            defaultValue={'WARN'}
                                            value={formData.log_level}
                                            onChange={handleChange}
                                            classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                            style={{ minWidth: 200 }}
                                        >
                                            {log_level_menu.map((item) =>
                                            <MenuItem key={item} value={item}>{item}</MenuItem>)}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={2} className={classes.inputRow}>
                                        <InputLabel className={classes.dialog}>Log Interval</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className={classes.inputRow}>
                                        <ValidatedTextField
                                            name='log_interval'
                                            label='Seconds*'
                                            value={formData.log_interval}
                                            onChange={handleChange}
                                            variant={"outlined"}
                                            //className={classes.dialog}
                                            classes={{ root: classes.whiteColor }}
                                            sx={{
                                                width: 100
                                              }}
                                            inputProps={{ style: { color: "#FFF" } }}
                                            InputLabelProps={{ style: { color: "#AAA" } }}
                                            error={error}
                                            setError={setError}
                                            mode='log-interval'
                                            required='required'
                                            maxLength={4}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>
                                Created: {formatDate(formData.created ? formData.created : new Date())}  ({getTimeDeltaHuman(formData.created ? formData.created : new Date())})
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>
                                Modified: {formatDate(formData.modified ? formData.modified : new Date())} ({getTimeDeltaHuman(formData.modified ? formData.modified : new Date())})
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>
                                Used: {formData.ref_count ? formData.ref_count : 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                </DialogContentText>
                <DialogActions>
                    <Button className={classes.dialogWarning} onClick={() => handleOpenDeploy()} variant="contained" startIcon={<RocketLaunchOutlinedIcon />} disabled={requiresSave}>Deploy</Button>
                    <Button className={classes.dialogNeutral} onClick={() => { setError([]); onClose()} } variant="contained" startIcon={<DisabledByDefaultOutlinedIcon />}>Close</Button>
                    <Button className={classes.dialogConfirm} onClick={(event) => handleSubmit(event)} variant="contained" startIcon={<CheckBoxOutlinedIcon />} disabled={!anyErrors(error) || isRequiredFieldEmpty || ! requiresSave}>SAVE</Button>
                </DialogActions>
                <DeployDialog
                    type={'analyzer'}
                    open={deployMode}
                    onClose={handleCloseDeploy}
                    href={href}
                    cfn={cfn}
                    item={formData}
                />
                <DeployDialogWindows
                    open={deployWindowsMode}
                    onClose={handleCloseDeploy}
                    installer_url={installerUrl}
                    config_url={configUrl}
                    item={formData}
                />
            </DialogContent>
        </Dialog>
    )
}
