const Constants = {

  applicationName: 'RansomStop',

  customerName: 'secunis2',
  apiHost: 'secunis2-api',
  domainName: 'ransomstop.com',
  version: '24.8.26',
  userPoolId: 'us-east-1_VjBR8gbfk',
  userPoolClientId: '6v3dmsbq9f1b170pgi1e4c2dcf',
  authDomain: 'secunis2-ransomstop-com.auth.us-east-1.amazoncognito.com',
  region: 'us-east-1',
  invokeUrl: '',

}
export default Constants